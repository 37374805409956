import React from "react"

import Layout from "../components/layout";

const IndexPage = () => (
  <Layout>
    <h1 style={{color: '#111111', fontSize: '100px'}}>Under Construction</h1>
  </Layout>
)

export default IndexPage
